@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: lusitana;
  color: #545454;
}

@font-face {
  font-family: "circularstd";
  src: local("circularstd"),
    url("./fonts/CIRCULARSTD-LIGHT.OTF") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.MuiBreadcrumbs-li a {
  font-family: circularstd;
}

/* overflow y scroll bar design */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #0b0b2f;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1c1c55;
}

/* font weight  */
.fs-400 {
  font-weight: 400;
}
.fs-700 {
  font-weight: 700;
}

/* font-sizes for desktop */
.fs-14 {
  font-size: 0.72vw;
}
.fs-16 {
  font-size: 1vw;
}

.fs-18 {
  font-size: 1vw;
}
.fs-24 {
  font-size: 1.25vw;
}
.fs-40 {
  font-size: 2vw;
}

.fs-32 {
  font-size: 1.7vw;
}

.fs-28 {
  font-size: 1.4vw;
}

/*mobile font size  */
@media only screen and (max-width: 768px) {
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-24 {
    font-size: 14px;
  }
  .fs-28 {
    font-size: 18px;
  }
  .fs-32 {
    font-size: 4.5vw;
  }
  .fs-40 {
    font-size: 5.2vw;
  }
}

.animate-bg {
  /* background: #0b0b2f; */
  background-size: 0;
  transition: 1s;
}

.animate-bg:hover {
  background: #0b0b2f;
  background-size: 0;
}

.animate-bg:hover p,
.animate-bg:hover h1 {
  transition: 1s;
  color: #fff !important;
}

/* container padding  */
.css-1oqqzyl-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* service page animation  */
.hovered-bg {
  background-color: #0b0b2f;
  transition: background-position 10s;
  background-size: 100% 200%;
  background-position: 0 100%;
}

.hovered-bg:hover {
  background-position: 0 0;
}

.container-services {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 19% !important;
}

.top-div {
  position: relative;
  transition: height 0.3s ease;
}

.bottom-div {
  position: absolute;
  height: 3%;
  transition: height 0.3s ease;
  bottom: 0;
  background: radial-gradient(circle, #11115a, #0d0d3a);
}

.top-div {
  top: 0;
}

.container-services:hover .bottom-div {
  height: 35%;
  transform: scaleY(1);
}

.container-services:hover .p-tag p {
  z-index: 1;
  color: white !important;
  transition: transform 0.3s ease;
  transform: translateY(10px);
  font-weight: bold;
}

.top-div .p-tag {
  transition: transform 0.3s ease;
  color: white !important;
  z-index: 1;
}

.logoHover {
  background-color: #fff;
}
.logoHover:hover {
  background: radial-gradient(circle, #11115a, #0d0d3a);
}
.logoHover:hover img {
  filter: brightness(0) invert(1) grayscale(100%) brightness(1.2);
}

/* ABOUT PAGE ANIMATION */

.about-container-services {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.top-div-about .about-p-tag {
  transition: transform 0.3s ease;
  color: white !important;
  z-index: 1;
}
.about-bottom-div {
  position: absolute;
  height: 1.5vh;
  transition: height 0.3s ease;
  bottom: 0;
  background: radial-gradient(circle, #11115a, #0d0d3a);
}

.about-container-services:hover .about-bottom-div {
  height: 25vh;
  transform: scaleY(1);
}

.top-div-about {
  top: 0;
}

.top-div-about {
  position: relative;
  transition: height 0.3s ease;
  height: 100%;
}

.about-container-services:hover .top-div-about {
  position: relative;
  transition: height 0.3s ease;
  height: 100%;
}

.about-container-services:hover .about-p-tag p {
  z-index: 1;
  color: white !important;
  transition: transform 0.3s ease;
  transform: translateY(10px);
}

.top-div .about-p-tag {
  transition: transform 0.3s ease;
  color: white !important;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  /* Mobile styles */

  .container-services:hover .about-p-tag p {
    z-index: 1;
    color: #0b0b2f !important;
    transition: transform 0.3s ease;
    transform: translateY(0px);
  }

  .about-container-services:hover .p-tag p {
    z-index: 1;
    color: #0b0b2f !important;
    transition: transform 0.3s ease;
    transform: translateY(0px);
  }

  .mobile-list {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .mobile-list-item {
    flex: 0 0 100%;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
    /* Add additional styling as needed */
  }

  .mobile-nav-buttons {
    display: flex;
    justify-content: space-between;
  }
  .logoHover {
    background-color: #f2f2f2;
  }
}
/* ---------------------animarted hedaer menu-------------- */
/* Add this to your global styles or component styles */
@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.slide-in {
  animation: slideIn 0.7s ease-in-out;
}

.slide-out {
  animation: slideOut 0.7s ease-in-out;
}

/* -------------------seek bar hide from opening video--------------- */

.my-video::-webkit-media-controls-timeline {
  display: none !important;
}

.my-video::-webkit-media-controls {
  display: none !important;
}

.my-video::-webkit-media-controls-panel {
  display: none !important;
}


